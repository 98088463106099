import $ from "jquery";
import { SimpleAccordion } from "../accordion/SimpleAccordion";

export class SlateSortState {
  constructor($el) {
    this.$el = $el;
    console.log(this.$el);
    $(this.$el).css("opacity", 0);
    this.$slateListItems = $(this.$el).find(".slate-event-list-item");
    this.$slateListUL = $(this.$el).find(".slate-event-list");
    var slateEventsSortedByState = this.$slateListItems
      .detach()
      .sort(this.dynamicSort("data-slate-event-location"));
    this.$slateaccordionGroup = $(this.$el).find(
      ".slate-accordion-group__accordions"
    );
    this.noFairs = true;

    this.$alabama = [];
    this.$alaska = [];
    this.$arizona = [];
    this.$arkansas = [];
    this.$california = [];
    this.$colorado = [];
    this.$connecticut = [];
    this.$delaware = [];
    this.$districtOfColumbia = [];
    this.$florida = [];
    this.$georgia = [];
    this.$hawaii = [];
    this.$idaho = [];
    this.$illinois = [];
    this.$indiana = [];
    this.$iowa = [];
    this.$kansas = [];
    this.$kentucky = [];
    this.$louisiana = [];
    this.$maine = [];
    this.$maryland = [];
    this.$massachusetts = [];
    this.$michigan = [];
    this.$minnesota = [];
    this.$mississippi = [];
    this.$missouri = [];
    this.$montana = [];
    this.$nebraska = [];
    this.$nevada = [];
    this.$newHampshire = [];
    this.$newJersey = [];
    this.$newMexico = [];
    this.$newYork = [];
    this.$northCarolina = [];
    this.$northDakota = [];
    this.$ohio = [];
    this.$oklahoma = [];
    this.$oregon = [];
    this.$pennsylvania = [];
    this.$puertoRico = [];
    this.$rhodeIsland = [];
    this.$southCarolina = [];
    this.$southDakota = [];
    this.$tennessee = [];
    this.$texas = [];
    this.$utah = [];
    this.$vermont = [];
    this.$virginia = [];
    this.$virginIslands = [];
    this.$washington = [];
    this.$westVirginia = [];
    this.$wisconsin = [];
    this.$wyoming = [];

    for (var i = 0; i < this.$slateListItems.length; i++) {
      //divide by state place into Arrays
      if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "AL"
      ) {
        this.$alabama.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Alabama"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "AK"
      ) {
        this.$alaska.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Alaska"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "AZ"
      ) {
        this.$arizona.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Arizona"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "AR"
      ) {
        this.$arkansas.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Arkansas"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "CA"
      ) {
        this.$california.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "California"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "CO"
      ) {
        this.$colorado.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Colorado"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "CT"
      ) {
        this.$connecticut.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Connecticut"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "DE"
      ) {
        this.$delaware.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Delaware"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "DC"
      ) {
        this.$delaware.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "District of Columbia"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "FL"
      ) {
        this.$florida.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Florida"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "GA"
      ) {
        this.$georgia.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Georgia"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "HI"
      ) {
        this.$hawaii.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Hawaii"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "ID"
      ) {
        this.$idaho.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Idaho"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "IL"
      ) {
        this.$illinois.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Illinois"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "IN"
      ) {
        this.$indiana.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Indiana"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "IA"
      ) {
        this.$iowa.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Iowa"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "KS"
      ) {
        this.$kansas.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Kansas"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "KY"
      ) {
        this.$kentucky.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Kentucky"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "LA"
      ) {
        this.$louisiana.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Louisiana"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "ME"
      ) {
        this.$maine.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Maine"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "MD"
      ) {
        this.$maryland.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Maryland"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "MA"
      ) {
        this.$massachusetts.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Massachusetts"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "MI"
      ) {
        this.$michigan.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Michigan"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "MN"
      ) {
        this.$minnesota.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Minnesota"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "MS"
      ) {
        this.$mississippi.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Mississippi"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "MO"
      ) {
        this.$missouri.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Missouri"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "MT"
      ) {
        this.$montana.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Montana"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "NE"
      ) {
        this.$nebraska.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Nebraska"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "NV"
      ) {
        this.$nevada.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Nevada"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "NH"
      ) {
        this.$newHampshire.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "New Hampshire"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "NJ"
      ) {
        this.$newJersey.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "New Jersey"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "NM"
      ) {
        this.$newMexico.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "New Mexico"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "NY"
      ) {
        this.$newYork.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "New York"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "NC"
      ) {
        this.$northCarolina.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "North Carolina"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "ND"
      ) {
        this.$northDakota.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "New Dakota"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "OH"
      ) {
        this.$ohio.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Ohio"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "OK"
      ) {
        this.$oklahoma.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Oklahoma"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "OR"
      ) {
        this.$oregon.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Oregon"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "PA"
      ) {
        this.$pennsylvania.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Pennsylvania"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "RI"
      ) {
        this.$rhodeIsland.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Rhode Island"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "PR"
      ) {
        this.$delaware.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Puerto Rico"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "SC"
      ) {
        this.$southCarolina.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "South Carolina"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "SD"
      ) {
        this.$southDakota.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "South Dakota"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "TN"
      ) {
        this.$tennessee.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Tennessee"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "TX"
      ) {
        this.$texas.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Texas"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "UT"
      ) {
        this.$utah.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Utah"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "VT"
      ) {
        this.$vermont.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Vermont"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "VA"
      ) {
        this.$virginia.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Virginia"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "VI"
      ) {
        this.$virginia.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Virgin Islands"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "WA"
      ) {
        this.$washington.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Washington"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "WV"
      ) {
        this.$westVirginia.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "West Virginia"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "WI"
      ) {
        this.$wisconsin.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Wisconsin"
        );
      } else if (
        this.$slateListItems[i].getAttribute("data-slate-event-location") ==
        "WY"
      ) {
        this.$wyoming.push(this.$slateListItems[i]);
        this.$slateListItems[i].setAttribute(
          "data-slate-event-location",
          "Wyoming"
        );
      }

    }

    this.$states = [
      this.$alabama,
      this.$alaska,
      this.$arizona,
      this.$arkansas,
      this.$california,
      this.$colorado,
      this.$connecticut,
      this.$delaware,
      this.$districtOfColumbia,
      this.$florida,
      this.$georgia,
      this.$hawaii,
      this.$idaho,
      this.$illinois,
      this.$indiana,
      this.$iowa,
      this.$kansas,
      this.$kentucky,
      this.$louisiana,
      this.$maine,
      this.$maryland,
      this.$massachusetts,
      this.$michigan,
      this.$minnesota,
      this.$mississippi,
      this.$missouri,
      this.$montana,
      this.$nebraska,
      this.$nevada,
      this.$newHampshire,
      this.$newJersey,
      this.$newMexico,
      this.$newYork,
      this.$northCarolina,
      this.$northDakota,
      this.$ohio,
      this.$oklahoma,
      this.$oregon,
      this.$pennsylvania,
      this.$puertoRico,
      this.$rhodeIsland,
      this.$southCarolina,
      this.$southDakota,
      this.$tennessee,
      this.$texas,
      this.$utah,
      this.$vermont,
      this.$virginia,
      this.$washington,
      this.$westVirginia,
      this.$wisconsin,
      this.$wyoming,
    ];

    for (let state = 0; state < this.$states.length; state++) {
      if (this.$states[state].length > 0) {
        this.noFairs = false;
        this.makeAccordionFromState(this.$states[state]);
      }
    }
    if (this.noFairs) {
    } else {

    var thisEl = this.$el;
      $(function () {
        const $accordions = $(thisEl).find(".js-simple-accordion");
        console.log($accordions);
        if ($accordions.length) {
          $.makeArray($accordions).map((el, index) => {
            return new SimpleAccordion($(el), false, index + "-slateState");
          });
        }
      });
    }
    $(this.$el).css("opacity", 1);
    this.bindEvents();
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a.getAttribute(property) < b.getAttribute(property)
          ? -1
          : a.getAttribute(property) > b.getAttribute(property)
          ? 1
          : 0;
      return result * sortOrder;
    };
  }

  makeAccordionFromState(state) {
    const accord = document.createElement("div");
    accord.classList.add("simple-accordion");

    const button = document.createElement("button");
    button.classList.add("simple-accordion__trigger");
    button.innerHTML = state[0].getAttribute("data-slate-event-location");
    button.classList.add("js-simple-accordion");

    const span = document.createElement("span");
    span.classList.add("simple-accordion__plus-sign");

    const accordBody = document.createElement("div");
    accordBody.classList.add("simple-accordion__body");

    const accordBodyUL = document.createElement("ul");
    accordBodyUL.classList.add("simple-accordion__list");

    state.forEach(function (item) {
      accordBodyUL.appendChild(item);
    });
    accordBody.appendChild(accordBodyUL);
    button.appendChild(span);
    accord.appendChild(button);
    accord.appendChild(accordBody);
    $(this.$slateaccordionGroup).append(accord);
  }

  bindEvents() {}
}
