import PropTypes from "prop-types";
import React from "react";

function createMarkup(htmlstring) {
  return { __html: htmlstring };
}

export default class DirectoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
  }

  componentDidMount() {
    const { index, query } = this.props;
    const focusTarget = query.perPage * (query.page - 1);

    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
    if (focusTarget === index) {
      this.itemWrapper.focus();
    }
  }

  render() {
    const {
      contactItems,
      contentUrl,
      image,
      imageAlt,
      location,
      name,
      pronouns,
      relevance,
      title,
    } = this.props;

    return (
      <div className="directory-list-item">
        {relevance && (
          <div className="directory-list-item__relevance">{relevance}</div>
        )}
        <div className="directory-list-item__content">
          {image && (
            <div className="directory-list-item__image">
              <img src={image} alt={imageAlt} />
            </div>
          )}
          <div className="directory-list-item__body">
            <div
              ref={(el) => (this.itemWrapper = el)}
              className="directory-list-item__name"
            >
              {contentUrl && <a href={contentUrl}>{name}</a>}
              {!contentUrl && <span>{name}</span>}
              {pronouns && <span className="directory-list-item__pronouns">&nbsp;{pronouns}</span>}
              <br />
              <span className="directory-list-item__title">{title}</span>
            </div>
            <div className="directory-list-item__info">
              {contactItems && (
                <div className="directory-list-item__contact">
                  {contactItems.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="directory-list-item__contact-item"
                      >
                        <a className="icon-link" href={item.url}>
                          <span
                            className="icon-link__icon"
                            dangerouslySetInnerHTML={createMarkup(item.icon)}
                          ></span>
                          <span className="icon-link__text">{item.text}</span>
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
              {location && (
                <div className="directory-list-item__location">
                  {location.map((line, i) => {
                    return (
                      <div
                        key={i}
                        className="directory-list-item__location-line"
                      >
                        {line}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const { array, number, shape, string } = PropTypes;
DirectoryItem.propTypes = {
  contactItems: array,
  contentUrl: string,
  image: string,
  imageAlt: string,
  index: number,
  location: array,
  name: string,
  pronouns: string,
  query: shape({
    q: string,
    sortBy: string,
    page: number,
    perPage: number,
    pageId: string,
  }),
  relevance: string,
  title: string.isRequired,
};
