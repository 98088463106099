import $ from "jquery";

export class LinkListSubFootColumns {
    constructor($el) {
        this.$el = $el;
        this.container = this.$el.find(".link-list-foot__list");
        this.parent = this.container[0].closest('.subfooter__column');
        this.updateGridColumns();
        this.bindEvents();
    }

    bindEvents() {
        window.addEventListener('resize', this.updateGridColumns.bind(this));
    }

    updateGridColumns() {
        const thisQuickLinks = this;
        if (this.parent) {
            const parentWidth = this.parent.offsetWidth;
            let columns = 1;

            if (parentWidth >= 500 && parentWidth < 900) { // Adjust the widths based on actual design requirements
                columns = 1;
            } else if (parentWidth >= 900 && parentWidth < 1500) {
                columns = 2;
            } else if (parentWidth >= 1500 ) {
                columns = 4;
            }

            thisQuickLinks.container[0].style.gridTemplateColumns = `repeat(${columns}, 1fr)`;
        }
    }
}