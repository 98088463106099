import { link } from "d3";
import $ from "jquery";

export class AnchorLinks {
  constructor($el) {
    this.$el = $el;
    this.$anchor0 = $el.find("#anchor0 a");
    this.$anchor1 = $el.find("#anchor1 a");
    this.$anchor2 = $el.find("#anchor2 a");
    this.$anchor3 = $el.find("#anchor3 a");
    this.$anchor4 = $el.find("#anchor4 a");
    this.$anchor5 = $el.find("#anchor5 a");

    this.$anchorline1 = $el.find("#anchorline1");
    this.$anchorline2 = $el.find("#anchorline2");
    this.$anchorline3 = $el.find("#anchorline3");
    this.$anchorline4 = $el.find("#anchorline4");
    this.$anchorline5 = $el.find("#anchorline5");

    this.$anchorLinkCount = 0;
    this.$anchorSectionActive = 0;
    this.$anchorNav = $el.find("#anchor-nav").get(0);
    this.$isStickyAnchor = false;
    this.isSticking = false;

    this.$spsshero = $(".single-page-hero");

    if (this.$anchorNav.classList.contains("anchor_stick-to-top")) {
      this.$isStickyAnchor = true;
    }
    this.$anchorArrowSavedLocation = 0;
    this.$headerHeight = $(document).find("header").height(); // universal yay!

    this.$anchorHeadings = $(".anchor-header");
    this.$anchors = [
      this.$anchor0,
      this.$anchor1,
      this.$anchor2,
      this.$anchor3,
      this.$anchor4,
      this.$anchor5,
    ];

    this.$anchorlines = [
      this.$anchorline1,
      this.$anchorline2,
      this.$anchorline3,
      this.$anchorline4,
      this.$anchorline5,
    ];

    this.$leftAnchorArrow = $el.find(".navigate-anchorlinks-left");
    this.$rightAnchorArrow = $el.find(".navigate-anchorlinks-right");
    this.anchorSeenOnLeft = 0;
    this.$scrollable = $el.find(".nav-holder")[0];
    this.anchorOffset = $(".js-anchor-links").offset().top;
    this.magicAnchors();

    this.$anchorLinkWidth = $el.find("#anchor0").width();
    this.$anchorLinksNavMargin = $el
      .find("#anchor-nav")
      .css("padding-left")
      .split("px")[0];
    this.scrolledPastHeader = false;
    this.bindEvents();
  }

  bindEvents() {
    let thisLink = this;
    let sizeOfAnchorLinks =
      thisLink.$scrollable.scrollWidth / thisLink.$anchorLinkCount;
    let newPosition = 0;
    let leftOver = 0;

    let anchor1LeftPos = 0;
    FindIfLeftArrowIsNeeded(thisLink.$scrollable.scrollLeft);
    FindIfRightArrowIsNeeded(thisLink.$scrollable.scrollLeft);
    OnScrollThings();

    $(window).on("scroll", function () {
      OnScrollThings();
    });

    for (let i = 0; i < thisLink.$anchors.length; i++) {
      let $this = thisLink.$anchors[i];

      $(thisLink.$anchors[i]).on("click", function (e) {
        OnScrollThings();
        //e.preventDefault();
        // let $headerHeight = $(".header-sticky").height();
        // if ($headerHeight == undefined) {
        //   $headerHeight = 0;
        // }
        let $stickyHeader = $(".js-sticky-scroll").height();
        if ($stickyHeader == undefined) {
          $stickyHeader = 0;
        }
        let leftAim =
          $this.offset().left -
          thisLink.$leftAnchorArrow.width() +
          thisLink.$scrollable.scrollLeft;
        thisLink.anchorSeenOnLeft = i;
        thisLink.$scrollable.scrollTo({
          left: leftAim,
          behavior: "smooth",
        });


      });

      
    }


    $(window).on("resize", function () {
      if ($(window).width() <= 860) {
        MobileScroll();
      } else {
        HideBothArrows();
      }
      $(thisLink.$el).removeClass("anchor-links-fixed-top");
      $(thisLink.$el).css("margin", "auto");
      thisLink.$headerHeight = $(document).find("header").height();
      thisLink.anchorOffset = $(thisLink.$el).offset().top;
      OnScrollThings();
    });

    function OnScrollThings() {
      let scrollAmt = $(window).scrollTop();
      let fullHeroHeight = $(".single-page-hero").height(); // not universal
      if (fullHeroHeight == undefined) {
        fullHeroHeight = 0;
      }

      let spsStickyHeight = $(".single-page-hero-sticky").height(); // not universal
      //if no sticky header is present
      if (spsStickyHeight == undefined) {
        spsStickyHeight = 0;
      }
      let anchorChange = spsStickyHeight + thisLink.$headerHeight;

      thisLink.$anchorSectionActive = 0;
      thisLink.$anchorHeadings.each(function () {
        let $this = $(this);
        let anchorH2 = $this.find("h2");
        if (scrollAmt > $this.offset().top - (anchorChange + 200)) {
          thisLink.$anchorSectionActive++;
        } else {
        }
      });

      if (thisLink.$anchorSectionActive > 0) {
        $(thisLink.$anchors).each(function () {
          let $this = $(this);
          $this.removeClass("anchor-link-active");
        });
        thisLink.$anchors[thisLink.$anchorSectionActive - 1].addClass(
          "anchor-link-active"
        );
      } else {
        $(thisLink.$anchors).each(function () {
          let $this = $(this);
          $this.removeClass("anchor-link-active");
        });
      }

      thisLink.headerHeight = $(document).find("header").height();
      if (thisLink.$isStickyAnchor) {
        if (
          scrollAmt >
            thisLink.anchorOffset - spsStickyHeight - thisLink.headerHeight &&
          window.innerWidth > 860
        ) {
          $(thisLink.$el).addClass("anchor-links-fixed-top");
          thisLink.isSticking = true;
          $(thisLink.$el).css(
            "top",
            thisLink.$headerHeight + spsStickyHeight + "px"
          );
          $(thisLink.$el).css("margin", "auto auto 3rem auto");
          if(thisLink.$spsshero != null && thisLink.$spsshero.length > 0){
            $(thisLink.$spsshero).css("margin-bottom", "6rem");
          }
        } else if (
          scrollAmt >
            thisLink.anchorOffset - spsStickyHeight - thisLink.headerHeight &&
          window.innerWidth < 860
        ) {
          $(thisLink.$el).addClass("anchor-links-fixed-top");
          thisLink.isSticking = true;
          $(thisLink.$el).css(
            "top",
            thisLink.$headerHeight + spsStickyHeight + "px"
          );
          $(thisLink.$el).css("margin", "auto auto 2rem auto");
          if(thisLink.$spsshero != null && thisLink.$spsshero.length > 0){
            $(thisLink.$spsshero).css("margin-bottom", "6rem");
          }
        } else {
          $(thisLink.$el).removeClass("anchor-links-fixed-top");
          thisLink.isSticking = false;
          if(window.innerWidth > 860){
          $(thisLink.$el).css("margin", "auto auto 3rem auto");
          } else{
            $(thisLink.$el).css("margin", "auto auto 2rem auto");
          }
          if(thisLink.$spsshero != null && thisLink.$spsshero.length > 0){
            $(thisLink.$spsshero).css("margin-bottom", "0");
          }
        }
      }
      let $stickyHeader = $(".js-sticky-scroll").height();
      if ($stickyHeader == undefined) {
        if(window.innerWidth > 1025){
          $stickyHeader = 130;
        } else if(window.innerWidth > 860 && window.innerWidth < 1025){
          $stickyHeader = 80;
        } else{
          $stickyHeader = 253;
        }
      } else{
        if(window.innerWidth < 1025 && !thisLink.isSticking){
          $stickyHeader += 0;
        } 
      }
      
      for (let i = 0; i < thisLink.$anchorHeadings.length; i++) {
        const anchorHead = thisLink.$anchorHeadings[i];
        $(anchorHead).css("scroll-margin-top", thisLink.$anchorNav.clientHeight + thisLink.$headerHeight + $stickyHeader + 25 );

      }
      
      FindIfLeftArrowIsNeeded(thisLink.$scrollable.scrollLeft);
      FindIfRightArrowIsNeeded(thisLink.$scrollable.scrollLeft);
    }
    $(thisLink.$scrollable).on("scroll", function () {
      MobileScroll();
    });

    function MobileScroll() {
      FindIfLeftArrowIsNeeded(thisLink.$scrollable.scrollLeft);
      FindIfRightArrowIsNeeded(thisLink.$scrollable.scrollLeft);
    }

    function HideBothArrows() {
      thisLink.$leftAnchorArrow.css("display", "none");
      thisLink.$rightAnchorArrow.css("display", "none");
    }

    //LEFT ARROW CLICK
    $(thisLink.$leftAnchorArrow).on("click", function () {
      var w = $(window).width();
      sizeOfAnchorLinks =
        thisLink.$scrollable.scrollWidth / thisLink.$anchorLinkCount;

      newPosition = thisLink.$scrollable.scrollLeft - w;
      if (newPosition < 0) {
        newPosition = 0;
        thisLink.$scrollable.scrollTo({
          left: 0,
          behavior: "smooth",
        });
        FindIfLeftArrowIsNeeded(0);
        FindIfRightArrowIsNeeded(0);
      } else if (w < sizeOfAnchorLinks * 2) {
        thisLink.anchorSeenOnLeft--;
        anchor1LeftPos =
          thisLink.$anchors[
            Math.floor(thisLink.anchorSeenOnLeft)
          ][0].getBoundingClientRect();

        thisLink.$scrollable.scrollTo({
          left:
            anchor1LeftPos.left +
            thisLink.$scrollable.scrollLeft -
            thisLink.$leftAnchorArrow.width(),
          behavior: "smooth",
        });
      } else {
        leftOver = newPosition % sizeOfAnchorLinks;
        thisLink.anchorSeenOnLeft = newPosition / sizeOfAnchorLinks;
        anchor1LeftPos =
          thisLink.$anchors[
            Math.floor(thisLink.anchorSeenOnLeft)
          ][0].getBoundingClientRect();

        thisLink.$scrollable.scrollTo({
          left:
            anchor1LeftPos.left +
            thisLink.$scrollable.scrollLeft -
            thisLink.$leftAnchorArrow.width(),
          behavior: "smooth",
        });

        FindIfLeftArrowIsNeeded(
          thisLink.$scrollable.scrollLeft -
            thisLink.$scrollable.scrollWidth / thisLink.$anchorLinkCount
        );
        FindIfRightArrowIsNeeded(
          thisLink.$scrollable.scrollLeft -
            thisLink.$scrollable.scrollWidth / thisLink.$anchorLinkCount
        );
      }
    });

    //RIGHT ARROW CLICK
    $(thisLink.$rightAnchorArrow).on("click", function () {
      var w = $(window).width();

      sizeOfAnchorLinks =
        thisLink.$scrollable.scrollWidth / thisLink.$anchorLinkCount;
      newPosition = thisLink.$scrollable.scrollLeft + w;
      leftOver = newPosition % sizeOfAnchorLinks;
      thisLink.anchorSeenOnLeft = newPosition / sizeOfAnchorLinks;

      anchor1LeftPos =
        thisLink.$anchors[
          Math.floor(thisLink.anchorSeenOnLeft)
        ][0].getBoundingClientRect();
      thisLink.$scrollable.scrollTo({
        left:
          anchor1LeftPos.left +
          thisLink.$scrollable.scrollLeft -
          thisLink.$leftAnchorArrow.width(),
        behavior: "smooth",
      });

      FindIfLeftArrowIsNeeded(
        thisLink.$scrollable.scrollLeft +
          thisLink.$scrollable.scrollWidth / thisLink.$anchorLinkCount
      );
      FindIfRightArrowIsNeeded(
        thisLink.$scrollable.scrollLeft +
          thisLink.$scrollable.scrollWidth / thisLink.$anchorLinkCount
      );
    });

    function FindIfLeftArrowIsNeeded(leftScrollAmt) {
      if (leftScrollAmt > 0) {
        thisLink.$leftAnchorArrow.css("display", "block");
      } else {
        thisLink.$leftAnchorArrow.css("display", "none");
      }
    }

    function FindIfRightArrowIsNeeded(leftScrollAmt) {
      // Calculate the maximum scrollable amount by subtracting the visible width from the total scroll width
      const maxScrollAmt = thisLink.$scrollable.scrollWidth - $(thisLink.$scrollable).outerWidth();
      
      // Check if we are at the maximum scroll position (plus a small buffer for browser rounding differences)
      if (leftScrollAmt >= maxScrollAmt - 2) {
        thisLink.$rightAnchorArrow.css("display", "none");
      } else {
        thisLink.$rightAnchorArrow.css("display", "block");
      }
    }

    thisLink.$el.on("click", function (e) {});
  }

  magicAnchors() {
    const thisLink = this;

    thisLink.$anchorHeadings.each(function () {
      let $this = $(this);
      var inneranchortext = $($this).find("h2").text().trim().replaceAll(" ", "").toLowerCase();
      //set header id
      $this.attr("id", inneranchortext);
      //set anchor link
      $(thisLink)
        .find("#anchor" + thisLink.$anchorLinkCount + " a")
        .attr("href", "#test");
      var spsStickyHeight = $(".single-page-hero-sticky").height(); //not universal
      if (spsStickyHeight == undefined) {
        spsStickyHeight = 0;
      }
      var headerHeight = $("header").height();
      var j = $(thisLink.$el).height();


      thisLink.$anchors[thisLink.$anchorLinkCount].text(
        $this.attr("data-anchor-title")
      );
      thisLink.$anchors[thisLink.$anchorLinkCount].attr(
        "href",
        "#" + inneranchortext
      );
      var line = document.createElement("div");
      line.classList.add("anchor-line");

      thisLink.$anchorLinkCount++;
    });

    //Remove Links if there are less than 6
    if (thisLink.$anchorLinkCount < 1) {
      //remove nothing so the pattern shows in pattern lab
    } else if (thisLink.$anchorLinkCount < 6) {
      for (let i = thisLink.$anchorLinkCount; i < 6; i++) {
        thisLink.$anchors[i].parent("div").remove();
        thisLink.$anchors[i].remove();
        thisLink.$anchorlines[i - 1].remove();
      }
    }

    $(thisLink.$anchorNav).css("opacity", 1);
  }
}
