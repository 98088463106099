import $ from "jquery";
import { SimpleAccordion } from "../accordion/SimpleAccordion";

export class SlateSortCountry {
  constructor($el) {
    this.$el = $el;
    $(this.$el).css("opacity", 0);
    this.$slateListItems = $(this.$el).find(".slate-event-list-item");
    this.$slateListUL = $(this.$el).find(".slate-event-list");
    //sort alphabetically by country
    var slateEventsSortedByCountry = this.$slateListItems
      .detach()
      .sort(this.dynamicSort("data-slate-event-country"));
    this.$slateaccordionGroup = $(this.$el).find(
      ".slate-accordion-group__accordions"
    );
    this.noFairs = true;

    this.countryList = [];
    this.countryArray = [];

    //create an array of unique countries
    for (var i = 0; i < this.$slateListItems.length; i++) {
      let newCountry = true;
      //divide by country
      var country = this.$slateListItems[i].getAttribute(
        "data-slate-event-country"
      );
      this.countryList.forEach((element) => {
        if (newCountry && element != country) {
          newCountry = true;
        } else if (element == country) {
          newCountry = false;
        }
      });
      if (newCountry) {
        this.countryList.push(country);
      }
    }

    //create an array of events for each country

    for (let country = 0; country < this.countryList.length; country++) {
      var eventsInCountries = [];
      for (var i = 0; i < this.$slateListItems.length; i++) {
        if (
          this.$slateListItems[i].getAttribute("data-slate-event-country") ==
          this.countryList[country]
        ) {
          eventsInCountries.push(this.$slateListItems[i]);
        }
      }
      this.countryArray.push(eventsInCountries);
    }

    for (let country = 0; country < this.countryArray.length; country++) {
      if (this.countryArray[country].length > 0) {
        this.noFairs = false;
        this.makeAccordionFromState(this.countryArray[country]);
      }
    }

    if (this.noFairs) {
    } else {
        var thisEL = this.$el;
      $(function () {
        const $accordions = $(thisEL).find(".js-simple-accordion");

        if ($accordions.length) {
          $.makeArray($accordions).map((el, index) => {
            return new SimpleAccordion($(el), false, index + "-slateCountry");
          });
        }
      });
    }
    $(this.$el).css("opacity", 1);
    this.bindEvents();
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a.getAttribute(property) < b.getAttribute(property)
          ? -1
          : a.getAttribute(property) > b.getAttribute(property)
          ? 1
          : 0;
      return result * sortOrder;
    };
  }

  makeAccordionFromState(state) {
    const accord = document.createElement("div");
    accord.classList.add("simple-accordion");

    const button = document.createElement("button");
    button.classList.add("simple-accordion__trigger");
    button.innerHTML = state[0].getAttribute("data-slate-event-country");
    button.classList.add("js-simple-accordion");

    const span = document.createElement("span");
    span.classList.add("simple-accordion__plus-sign");

    const accordBody = document.createElement("div");
    accordBody.classList.add("simple-accordion__body");

    const accordBodyUL = document.createElement("ul");
    accordBodyUL.classList.add("simple-accordion__list");

    state.forEach(function (item) {
      accordBodyUL.appendChild(item);
    });
    accordBody.appendChild(accordBodyUL);
    button.appendChild(span);
    accord.appendChild(button);
    accord.appendChild(accordBody);
    $(this.$slateaccordionGroup).append(accord);
  }

  bindEvents() {}
}
