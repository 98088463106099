import $ from "jquery";
import { breakpoint } from "../breakpoint";

let throttler;

/*
 * SimpleAccordion
 * Simply a button that toggles the element that immediately follows it.
 * Can take "false" for activeIn to be active everywhere, or an array of breakpoints to be active
 *   in.  These need to match the breakpoint content values in the SCSS
 */
export class SimpleAccordion {
  constructor($el, activeIn, index) {
    this.$el = $el;
    this.$el.attr("id", `simple-accordion-button-${index}`);
    this.activeIn = activeIn;
    this.index = index;
    this.$target = this.$el.next();
    this.$target.attr("id", `simple-accordion-target-${index}`);
    this.$target.attr("aria-labelledby", this.$el.attr("id"));
    this.currentBreakpoint = "";

    // Only prepare if we're not in the Sitecore page editor
    if (
      !document.body.classList.contains("is-page-editor") &&
      !document.querySelector("html").classList.contains("is-page-editor")
    ) {
      /* Hide if for all devices, or if mobile only and in mobile mode */
      if (
        (this.activeIn && this.activeIn.indexOf(breakpoint()) > -1) ||
        !this.activeIn
      ) {
        this.$target.hide();
      }

      this.$el.attr("aria-expanded", this.$el.hasClass("is-open"));
      this.$el.attr("aria-controls", this.$target.attr("id"));

      this.bindEvents();
    }
  }

  bindEvents() {
    let thisAccordion = this;

    thisAccordion.$el.on("click", function () {
      thisAccordion.$el.toggleClass("is-open");
      thisAccordion.$el.attr("aria-expanded", thisAccordion.$el.hasClass("is-open"));
      thisAccordion.$target.slideToggle();
    });

    thisAccordion.currentBreakpoint = breakpoint();



    $(window).on("resize", (event) => {
      
      if (throttler) {
      }
      throttler = setTimeout(() => {
        thisAccordion.manageBreakpointBehaviors();
      }, 400);

    });
  }

  manageBreakpointBehaviors() {
    if(this.activeIn) {
    }
    // First, check if we need to manage breakpoint behaviors (has the breakpoint actually changed)
    if (this.currentBreakpoint !== breakpoint()) {
      if (this.activeIn && this.activeIn.indexOf(breakpoint()) > -1) {
        this.$target.hide();
        this.$el.removeClass("is-open");
        this.$el.attr("aria-expanded", "false");
      }
      else if (!this.activeIn){
        // Don't do anything
      } else {
        this.$target.show();
        this.$el.addClass("is-open");
        this.$el.attr("aria-expanded", "true");
      }
    }

    // Update the current breakpoint
    this.currentBreakpoint = breakpoint();
  }
}
