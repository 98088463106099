import $ from "jquery";

export class SinglePageScrollerHero {
      constructor(selector, scrollOffset) {
        this.$el = $(selector);
        this.$headerHeight = $(document).find("header").height();
        this.$stickyHeader = $(this.$el).find(".single-page-hero-sticky");
        this.$heroFullHeight = $(this.$el).height();
        this.$image = $(this.$el).find("#parallax_image").attr("src");
        if(this.$image !== undefined){
          this.$backgroundParallax = $(this.$el).find(".single-page-hero__background");
          this.$backgroundParallax.css("background-image", "url(" + this.$image + ")");
        }
        this.$singlePageOverlay = $(this.$el).find(".single-page-hero__overlay");
        this.isMobile = false;
        this.$subheading = $(this.$el).find(".mobile-overlay-background");
        this.isTouchDevice = this.isTouchDevice();

        this.checkIfMobile();
        
        this.$stickyOnMobile = $(this.$el).find(".single-page-hero-sticky-mobile");
        this.isStickyOnMobile = false;
        if(this.$stickyOnMobile.length > 0){
          this.isStickyOnMobile = true;
        }

        this.$backgroundOverlay = $(this.$el).find(".overlay-background");
        this.savedBottom = $(this.$backgroundOverlay).css("bottom");
        this.savedBottomPercentage = 60;
        this.smallHeight();
        this.offsetOverlay = 1;
        this.checkIfAbsolute();
        this.bindEvent();
      }

      isTouchDevice() {
        return (('ontouchstart' in window) ||
           (navigator.maxTouchPoints > 0) ||
           (navigator.msMaxTouchPoints > 0));
      }

      checkIfMobile(){
        const thisSticky = this;
        if(window.innerWidth < 860){
          thisSticky.isMobile = true;
        } else {
          thisSticky.isMobile = false;
        }
      }

      smallHeight(){
        if(window.innerWidth < 860 && this.isTouchDevice){ 
          this.$el.css("height", "55vh");
          this.savedBottomPercentage = 55;
        } else if(window.innerWidth < 860 && !this.isTouchDevice){ 
          this.$el.css("height", "30vh");
          this.savedBottomPercentage = 30;
        } else {
          this.$el.css("height", "70vh");
          this.savedBottomPercentage = 70;
        }
      }

      bindEvent() {
        const thisSticky = this;

        $(window).on('scroll', () => {
          handleScroll();
        });

        $(window).on('resize', () => {
          thisSticky.smallHeight();
          thisSticky.checkIfMobile();

          thisSticky.$headerHeight = $(document).find("header").height();
          thisSticky.$heroFullHeight = $(thisSticky.$el).height();

          // if ($(window).scrollTop() > thisSticky.$heroFullHeight - thisSticky.$headerHeight) {
          //   thisSticky.$stickyHeader.addClass('js-sticky-scroll');
          // } else {
          //   thisSticky.$stickyHeader.removeClass('js-sticky-scroll');
          // }
          handleScroll();
        });

        $(function() {
          thisSticky.savedBottom = ($(window).height() * thisSticky.savedBottomPercentage / 100);
          thisSticky.offsetOverlay = +thisSticky.$backgroundOverlay.css("bottom").replace('px', '') - thisSticky.savedBottom - thisSticky.$el.height();
          thisSticky.$heroFullHeight = $(thisSticky.$el).height();
          handleScroll();

        });

      
        const handleScroll = () => {

          const windowWidth = $(window).width();
          thisSticky.savedBottom = ($(window).height() * .35);
          thisSticky.checkIfMobile();
          thisSticky.$headerHeight = $(document).find("header").height();
            const scrollTop = $(window).scrollTop();
            const windowHeight = $(window).height();

            const off = thisSticky.savedBottom- (windowHeight - thisSticky.$heroFullHeight );
            var restofPage = (windowHeight - thisSticky.$heroFullHeight);
            var bottomTrigger = thisSticky.savedBottom - restofPage;

            if (window.innerWidth > 860 && scrollTop >  bottomTrigger) {
              thisSticky.$backgroundOverlay.css('position', 'relative');
              thisSticky.$backgroundOverlay.css('bottom', thisSticky.$backgroundOverlay.css("height"));
              thisSticky.$backgroundOverlay.show();
            }
            else if (window.innerWidth > 860 && scrollTop < bottomTrigger) {
              thisSticky.$backgroundOverlay.css('position', 'fixed');
              thisSticky.$backgroundOverlay.css('bottom', thisSticky.savedBottom);
              thisSticky.$backgroundOverlay.show();
              thisSticky.$backgroundOverlay.show();
              thisSticky.$stickyHeader.removeClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("height", "0px");
            }
            else if(window.innerWidth < 860){
              thisSticky.$backgroundOverlay.hide();
              thisSticky.$stickyHeader.addClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("top", thisSticky.$headerHeight + "px");
              thisSticky.$stickyHeader.css("height", "auto");
            }
            else {
              thisSticky.$backgroundOverlay.css('position', 'fixed');
              thisSticky.$backgroundOverlay.css('bottom', thisSticky.savedBottom);
              thisSticky.$backgroundOverlay.show();
            }            
            
            let elementOffset = $(thisSticky.$backgroundOverlay).offset().top;
            const distance = (elementOffset - scrollTop);

            if(elementOffset == 0){
              elementOffset = thisSticky.$headerHeight * 2;
            }

            if (scrollTop > thisSticky.$heroFullHeight - thisSticky.$headerHeight - thisSticky.$backgroundOverlay.height() && !thisSticky.isMobile) {
              thisSticky.$backgroundOverlay.hide();
              thisSticky.$stickyHeader.addClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("top", thisSticky.$headerHeight + "px");
              thisSticky.$stickyHeader.css("height", "auto");
            } else if (scrollTop > elementOffset && thisSticky.isMobile && thisSticky.isStickyOnMobile) {
              thisSticky.$backgroundOverlay.hide();
              thisSticky.$stickyHeader.addClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("top", thisSticky.$headerHeight + "px");
              thisSticky.$stickyHeader.css("height", "auto");
            } else if (scrollTop < (thisSticky.$headerHeight*2) ){
            }
            else {
              thisSticky.$backgroundOverlay.show();
              thisSticky.$stickyHeader.removeClass('js-sticky-scroll');
              thisSticky.$stickyHeader.css("height", "0px");
            }

        };
    
      
      }
      checkIfAbsolute(){
        if(this.$singlePageOverlay.css("position") === "absolute"){
        }
      }
    }