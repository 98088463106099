// Libraries
import "./polyfill/index.js";
import $ from "jquery";
import "picturefill";

// Search
import { createApp } from "./drexel/search/factory";
import {
  defaultFacetedSearchFactory,
  defaultDirectorySearchFactory,
  defaultDynamicContentFactory,
  defaultEventContentFactory,
  defaultVisitContentFactory,
  defaultProgramContentFactory,
  defaultStaffSearchFactory,
  defaultVideoContentFactory,
  defaultAdmissionRepSearchFactory,
  defaultInTheNewsSearchFactory,
} from "./drexel/search";

// accessibility
import initClickableCard from "./drexel/a11y/clickableCard.js";

// Components
import { AccessibleLabels } from "./drexel/forms/AccessibleLabels";
import { Alert } from "./drexel/alerts/Alert";
import { PolicyDirectory } from "./drexel/policy/policyDirectory.js";
import { AnchorLinks } from "./drexel/navigation/AnchorLinks";
import { AnimatedHero } from "./drexel/effects/AnimatedHero";
import { Fabs } from "./drexel/callouts/fabs.js";
import { ImageSlider } from "./drexel/ImageSlider";
import { ImageZoom } from "./drexel/effects/ImageZoom";
import { Stats } from "./drexel/effects/Stats";
import { Tour } from "./drexel/tour/Tour";
import { ExploreMap } from "./drexel/explore-map/ExploreMap";

import { GrabQuery } from "./drexel/navigation/GrabQuery";
import { Hamburger } from "./drexel/navigation/Hamburger";
import { MicrositeHeader } from "./drexel/navigation/MicrositeHeader";
import { ShareButtons } from "./drexel/navigation/ShareButtons";
import { PrimaryNav } from "./drexel/navigation/PrimaryNav";
import { SearchFlyout } from "./drexel/navigation/SearchFlyout";
import { ReturnToTop } from "./drexel/navigation/ReturnToTop";
import { SinglePageScrollerHero } from "./drexel/header-heros/SinglePageScrollerHero.js";

import { SimpleAccordion } from "./drexel/accordion/SimpleAccordion";
import { SimpleMore } from "./drexel/accordion/SimpleMore";

import { BleedHeroVideo } from "./drexel/video/BleedHeroVideo";
import { MotionHeroVideo } from "./drexel/video/MotionHeroVideo";
import { SlideshowVideo } from "./drexel/video/SlideshowVideo";
import { Tabs } from "./drexel/tabs/Tabs";
import { VideoAccordion } from "./drexel/video/VideoAccordion";
import { Video } from "./drexel/video/Video";
import { VideoGrid } from "./drexel/video/VideoGrid";

import { initListApp } from "./drexel/lists";
import { FilterableTable } from "./drexel/tables/filterable-tables/FilterableTable.js";
import { SortableTable } from "./drexel/tables/sortable-tables/SortableTable.js";
import { SearchableTable } from "./drexel/tables/searchable-tables/SearchableTable.js";
import { SlateSortState } from "./drexel/slate/SlateSortState.js";
import { SlateSortCountry } from "./drexel/slate/SlateSortCountry.js";
import { ResponsiveTable } from "./drexel/tables/responsive-tables/ResponsiveTable.js";
import { LinkListSubFootColumns } from "./drexel/lists/linkListSubFoot.js";

import { ParallaxImageBlock } from "./drexel/effects/ParallaxImageBlock";
import { toggleText } from "./drexel/listings/notice";
import { ExperienceDrexel } from "./drexel/hero/ExperienceDrexel.js";

// do not intialize slideshows or other motion effects inside
// page editor
if (
  !$("body").hasClass("is-page-editor") &&
  !$("html").hasClass("is-page-editor")
) {

  // Clickable cards
  document.querySelectorAll(".js-clickable-card").forEach(initClickableCard);

  // AccessibleLabels
  const $accessiblelabel = $(".js-label");
  if ($accessiblelabel.length) {
    $.makeArray($accessiblelabel).map((el) => {
      return new AccessibleLabels($(el));
    });
  }

  // Video
  const $videoaccordions = $(".js-video-accordion");
  if ($videoaccordions.length) {
    $.makeArray($videoaccordions).map((el) => {
      return new VideoAccordion($(el));
    });
  }

  // Tabs
  const $tabs = $(".js-tabs");
  if ($tabs.length) {
    $.makeArray($tabs).map((el) => {
      return new Tabs(el);
    });
  }

  const $videos = $(".js-video");
  if ($videos.length) {
    $.makeArray($videos).map((el) => {
      return new Video($(el));
    });
  }

  const $herovideos = $(".js-hero-video");
  if ($herovideos.length) {
    $.makeArray($herovideos).map((el) => {
      return new MotionHeroVideo($(el));
    });
  }

  const $bleedherovideos = $(".js-bleed-hero-video");
  if ($bleedherovideos.length) {
    $.makeArray($bleedherovideos).map((el) => {
      return new BleedHeroVideo($(el));
    });
  }

  const $slideshowvideos = $(".js-slideshow-video");
  if ($slideshowvideos.length) {
    $.makeArray($slideshowvideos).map((el) => {
      return new SlideshowVideo($(el));
    });
  }

  const $videogrid = $(".js-video-grid");
  if ($videogrid.length) {
    $.makeArray($videogrid).map((el) => {
      const thisGrid = new VideoGrid($(el));
      thisGrid.init(); // initializing separately on purpose
      //return new VideoGrid($(el));
    });
  }

  const $imageslider = $(".slideshow .js-slider");
  if ($imageslider.length) {
    $.makeArray($imageslider).map((el) => {
      return new ImageSlider($(el), {});
    });
  }

  const $promosliders = $(".promo-slider .js-slider");
  if ($promosliders.length) {
    $.makeArray($promosliders).map((el) => {
      return new ImageSlider($(el), {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
        appendArrows: $(el),
        prevArrow: `
          <button type="button" class="slick-prev" tabindex="0" aria-label="go back one slide">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fill-rule="evenodd">
                  <path d="M4.34099026,11.3928571 L22,11.3928571 C22.4142136,11.3928571 22.75,11.7286436 22.75,12.1428571 C22.75,12.5570707 22.4142136,12.8928571 22,12.8928571 L4.34099026,12.8928571 L10.2035173,18.7553842 L9.14285714,19.8160444 L1.46966991,12.1428571 L9.14285714,4.46966991 L10.2035173,5.53033009 L4.34099026,11.3928571 Z"/>
                </g>
              </svg>
            </span>
          </button>`,
        nextArrow: `
          <button type="button" class="slick-next" tabindex="0" aria-label="advance one slide">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fill-rule="evenodd">
                  <path d="M4.34099026,11.3928571 L22,11.3928571 C22.4142136,11.3928571 22.75,11.7286436 22.75,12.1428571 C22.75,12.5570707 22.4142136,12.8928571 22,12.8928571 L4.34099026,12.8928571 L10.2035173,18.7553842 L9.14285714,19.8160444 L1.46966991,12.1428571 L9.14285714,4.46966991 L10.2035173,5.53033009 L4.34099026,11.3928571 Z"/>
                </g>
              </svg>
            </span>
        </button>`,
      });
    });
  }

  const $imagezooms = $(".js-image-zoom");
  if ($imagezooms.length) {
    $.makeArray($imagezooms).map((el) => {
      return new ImageZoom($(el));
    });
  }

  const $simpleaccordions = $(".js-accordion");
  if ($simpleaccordions.length) {
    $.makeArray($simpleaccordions).map((el) => {
      return new SimpleAccordion($(el), ["small", "xsmall", "xxsmall"]);
    });
  }

  const $accordions = $(".js-simple-accordion");
  if ($accordions.length) {
    $.makeArray($accordions).map((el, index) => {
      return new SimpleAccordion($(el), false, index);
    });
  }

  const $readmores = $(".js-simple-more");
  if ($readmores.length) {
    $.makeArray($readmores).map((el) => {
      return new SimpleMore($(el), false);
    });
  }

  // const $animatedheroes = $(".js-animated-hero");
  // if ($animatedheroes.length) {
  //   const animatedhero = new AnimatedHero(".js-animated-hero");
  // }

  const $stats = $(".js-stats");
  if ($stats.length) {
    const stats = new Stats(".js-stats");
  }
}

///////////////////////////////
//End of Is-Page-Editor Check
///////////////////////////////


// Primary Navigation
// Keep at top to run first
const $navbars = $(".js-primary-nav");
if ($navbars.length) {
  const primaryNavs = $.makeArray($navbars).map((el) => {
    return new PrimaryNav($(el));
  });
}


// tour
const $map = $(".js-map");
if ($map.length) {
  $.makeArray($map).map((el) => {
    return new Tour($(el), {});
  });
}

// explore by map
const $exploreByMap = $(".js-explore");
if ($exploreByMap.length) {
  $.makeArray($exploreByMap).map((el) => {
    return new ExploreMap($(el), {});
  });
}

// Hamburger Navigation
const $hamburgers = $(".js-hamburger");
if ($hamburgers.length) {
  $.makeArray($hamburgers).map((el) => {
    return new Hamburger($(el), true, true);
  });
}

// Hamburger Navigation
const $micrositeheader = $(".js-microsite-header");
if ($micrositeheader.length) {
  $.makeArray($micrositeheader).map((el) => {
    return new MicrositeHeader($(el), {});
  });
}

const $footerburger = $(".js-sticky-footer__menu-trigger");
if ($footerburger.length) {
  $.makeArray($footerburger).map((el) => {
    return new Hamburger($(el), false, false);
  });
}

const $share = $(".js-share");
if ($share.length) {
  const share = $.makeArray($share).map((el) => {
    return new ShareButtons($(el));
  });
}



// Alerts
const $alert = $(".js-alert");
if ($alert.length) {
  $.makeArray($alert).map((el) => {
    return new Alert($(el));
  });
}

// Header search flyout
const $searchflyout = $(".js-search-flyout");
if ($searchflyout.length) {
  $.makeArray($searchflyout).map((el) => {
    return new SearchFlyout($(el));
  });
}

// Sticky Scroll
const $stickyscroll = $(".single-page-hero");
if ($stickyscroll.length) {
  const stickyscroll = $.makeArray($stickyscroll).map((el) => {
    return new SinglePageScrollerHero($(el), 620); 
  });
}


// Return to Top
const $returntotop = $(".js-return-to-top");
if ($returntotop.length) {
  $.makeArray($returntotop).map((el) => {
    return new ReturnToTop($(el));
  });
}

// Search Apps
// only init if results are included
if ($(".js-search-results").length) {
  const searchId = $(".js-search-results").data("searchId");
  const searchApp = createApp(defaultFacetedSearchFactory(searchId));
  searchApp();
}

if ($(".js-directory-results").length) {
  const searchId = $(".js-directory-results").data("searchId");
  const searchApp = createApp(defaultDirectorySearchFactory(searchId));
  searchApp();
}

if ($(".js-dynamic-results").length) {
  const searchId = $(".js-dynamic-results").data("searchId");
  const dynamicContentApp = createApp(defaultDynamicContentFactory(searchId));
  dynamicContentApp();
}

if ($(".js-event-results").length) {
  const searchId = $(".js-event-results").data("searchId");
  const eventContentApp = createApp(defaultEventContentFactory(searchId));
  eventContentApp();
}

if ($(".js-program-results").length) {
  const searchId = $(".js-program-results").data("searchId");
  const programContentApp = createApp(defaultProgramContentFactory(searchId));
  programContentApp();
}

if ($(".js-staff-search-results").length) {
  const searchId = $(".js-staff-search-results").data("searchId");
  const staffsearchContentApp = createApp(defaultStaffSearchFactory(searchId));
  staffsearchContentApp();
}

if ($(".js-admissionrep-results").length) {
  const searchId = $(".js-admissionrep-results").data("searchId");
  const searchApp = createApp(defaultAdmissionRepSearchFactory(searchId));
  searchApp();
}

if ($(".js-visit-results").length) {
  const searchId = $(".js-visit-results").data("searchId");
  const eventContentApp = createApp(defaultVisitContentFactory(searchId));
  eventContentApp();
}

if ($(".js-video-results").length) {
  const searchId = $(".js-video-results").data("searchId");
  const videoContentApp = createApp(defaultVideoContentFactory(searchId));
  videoContentApp();
}

if ($(".js-inthenewssearch-results").length) {
  const searchId = $(".js-inthenewssearch-results").data("searchId");
  const searchApp = createApp(defaultInTheNewsSearchFactory(searchId));
  searchApp();
}


// List Apps
const $list = $(".js-list");
if ($list.length) {
  initListApp($.makeArray($list));
}

const $linkListColumns = $(".js-link-list-columns");
if ($linkListColumns.length) {
  const linkListColumns = $.makeArray($linkListColumns).map((el) => {
    return new LinkListSubFootColumns($(el));
  });
}


const $querylinks = $(".js-grab-query");
if ($querylinks.length) {
  $.makeArray($querylinks).map((el) => {
    return new GrabQuery($(el));
  });
}

// Responsive tables
const responsiveTables = Array.from(
  document.querySelectorAll(".js-responsive-table, .js-degrees-table"),
);
responsiveTables.forEach((table) => {
  return new ResponsiveTable(table);
});


// Parallax Image Block
const $parallaxImageBlocks = $(".js-parallax-image-block");
if ($parallaxImageBlocks.length) {
  const parallaxImageBlocks = $.makeArray($parallaxImageBlocks).map((el) => {
    return new ParallaxImageBlock($(el));
  });
}
//anchor links
const $anchorlinks = $(".js-anchor-links");
if ($anchorlinks.length) {
    new AnchorLinks($anchorlinks);
}
//Filtered Tables
const searchableTables = Array.from(
  document.querySelectorAll(".js-searchable-table"),
);
searchableTables.forEach((table) => {
  return new SearchableTable(table);
});

const filterableTables = Array.from(
  document.querySelectorAll(".js-filterable-table"),
);
filterableTables.forEach((table) => {
  return new FilterableTable(table);
});

const sortableTables = Array.from(
  document.querySelectorAll(".js-sortable-table"),
);
sortableTables.forEach((table) => {
  return new SortableTable(table);
});

// Listings - Notice - Toggle Text
const $toggleText = $(".notice-container");
if ($toggleText.length) {
  const listing = $.makeArray($toggleText).map((el) => {
    return new toggleText($(el));
  });
};

//Experience Drexel
const $experienceDrexel = $(".js-experience-drexel");
if ($experienceDrexel.length) {
    const experienceDrexel = $.makeArray($experienceDrexel).map((el) => {
        return new ExperienceDrexel($experienceDrexel);
    });
}

// Fabs
const $fabs = $(".js-fabs");
if ($fabs.length) {
  const fabs = $.makeArray($fabs).map((el) => {
    return new Fabs($(el));
  });
}
// Policy Directory
const $policyDirectory = $(".js-policy-directory");
if ($policyDirectory.length) {
  const policyDirectory = $.makeArray($policyDirectory).map((el) => {
    return new PolicyDirectory($(el));
  });
}

//SlateSortState
const $SlateSortState = $(".js-slate-sort-state");
if ($SlateSortState.length) {
    const slateSortState = $.makeArray($SlateSortState).map((el) => {
        return new SlateSortState(el);
    });
}

//SlateSortCountry
const $SlateSortCountry = $(".js-slate-sort-country");
if ($SlateSortCountry.length) {
    const slateSortCountry = $.makeArray($SlateSortCountry).map((el) => {
        return new SlateSortCountry(el);
    });
}

//no year and a.m. p.m. in the time